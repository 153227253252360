<template>
    <OrqDrawer
        :is-open="isDrawerOpen"
        :inverse="inverse"
        class="login--drawer"
        :class="{ inverse }"
        @closed="onClosed"
        @close="isDrawerOpen = false"
    >
        <OrqDrawerBody>
            <template #header>
                <StatusMessage :inverse="inverse" @ready="doFocus">
                    <template #notification>&nbsp;</template>
                    <template #default>
                        <OrqColumn :gap="16">
                            <div
                                v-dompurify-html="t('quickLogin.drawer.title')"
                                class="drawer--title"
                            />

                            <div class="drawer--subtitle">
                                {{ t('quickLogin.drawer.subTitle') }}
                            </div>
                        </OrqColumn>
                    </template>
                </StatusMessage>
            </template>

            <template #body>
                <StatusMessage :inverse="inverse">
                    <template #default>
                        <OrqColumn :gap="48">
                            <LoginError
                                v-if="errorResponse"
                                :code="errorResponse"
                                :inverse="inverse"
                                @click-link="clickLink"
                            />

                            <form action="#" autocomplete="off" @submit.prevent>
                                <OrqColumn :gap="42">
                                    <div v-rut-format style="width: 100%">
                                        <OrqInput
                                            id="rut"
                                            ref="rutInputRef"
                                            :error="rutError"
                                            :description="rutError ?? '&nbsp;'"
                                            :inverse="inverse"
                                            start-icon="user-login"
                                            label="RUT"
                                            placeholder="RUT"
                                            @inputchange="onChangeRut"
                                            @blur="vuelidateRut.$touch()"
                                        />
                                    </div>
                                    <OrqInput
                                        id="password"
                                        v-model="password"
                                        :inverse="inverse"
                                        type="password"
                                        start-icon="locker-close"
                                        label="Contraseña"
                                        placeholder="Contraseña"
                                        @inputchange="onChangePassword"
                                    />
                                    <OrqButton
                                        :loading="loading"
                                        label="Iniciar sesión"
                                        size="full"
                                        @click="doLogin"
                                        @key.enter="doLogin"
                                    />
                                    <div class="drawer--forgot">
                                        <a href="#" @click="onForgotPassword">
                                            ¿Olvidaste tu contraseña?
                                        </a>
                                    </div>
                                </OrqColumn>
                            </form>
                        </OrqColumn>
                    </template>
                </StatusMessage>
            </template>
        </OrqDrawerBody>
    </OrqDrawer>
</template>

<script setup>
import {
    OrqDrawer,
    OrqDrawerBody,
    OrqColumn,
    OrqButton,
    OrqInput,
} from '@digital/orquestra';
import { useI18n } from 'vue-i18n';
import { computed, nextTick, onUnmounted, ref, watch } from 'vue';
import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS } from '@/consts';
import { useAuthStore } from '@/_store/auth.store.js';
import { useUserStore } from '@/_store/user.store.js';
import { useAccountsStore } from '@/_store/accounts.store.js';
import { removeRutFormat } from '@/utils/text';
import { rutFormat as vRutFormat } from '@/directives/rutFormat';

import StatusMessage from '@/components/auth/StatusMessage.vue';
import LoginError from '@/components/LoginError/LoginError.vue';

import router from '@/router';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { isRUT } from '@/_helpers/rules.helper.js';

const route = useRoute();
const isDrawerOpen = ref(false);
const inverse = ref(false);
const rutInputRef = ref(null);

// Form data
const rut = ref('');
const password = ref('');
const onChangeRut = debounce((event) => {
    rut.value = event.target?.value;
    if (event.target.value !== '') {
        vuelidateRut.value.$touch();
    }
}, 500);
const onChangePassword = (event) => {
    password.value = event.target.value;
};

// Login
const { addListener, removeListeners } = useRootEvents();
const { t } = useI18n();
const authStore = useAuthStore();
const { errorResponse } = storeToRefs(authStore);

const userStore = useUserStore();
const accountStore = useAccountsStore();

const loading = ref(false);
import { useBlockedAccount } from '@/composables/useBlockedAccount';
import { debounce } from 'lodash';
const { popBlockedAccount } = useBlockedAccount();

const doLogin = async () => {
    loading.value = true;
    try {
        if (!rut.value || !password.value) {
            new Error();
        } else {
            await authStore.login({
                user: removeRutFormat(rut.value),
                password: password.value,
            });

            sessionStorage.removeItem('agencia');

            // gtag manager dl
            window.dataLayer.push({ event: 'login' });
            await userStore.getUser();
            
            await accountStore.getAccounts();
            accountStore.selectDefaultAccount({ selectFirstC4Account: true });

            isDrawerOpen.value = false;
            popBlockedAccount();
        }
    } catch (e) {
        onClosed();
        console.log('error', e);
    }
    loading.value = false;
    if (route.name === 'login') {
        router.push('/');
    }
};

const onForgotPassword = () => {
    isDrawerOpen.value = false;
    window.location.href = router.resolve({ name: 'forgot' }).href;
};

const onClosed = () => {
    password.value = '';
};

const vuelidateRut = useVuelidate(
    {
        isRut: isRUT(),
    },
    rut
);

const rutError = computed(() => {
    if (vuelidateRut.value?.$dirty && vuelidateRut.value?.isRut?.$invalid) {
        return vuelidateRut.value?.isRut?.$message;
    }
    return null;
});

//! this watch is to close the drawer if the route changes (in specific, when you click on request new password)
watch(
    () => route.name,
    (newVal, oldVal) => {
        if (isDrawerOpen.value && newVal != oldVal) {
            isDrawerOpen.value = false;
        }
    }
);

addListener(EVENTS.QUICK_LOGIN_OPEN, (evt) => {
    errorResponse.value = null;
    isDrawerOpen.value = true;
    inverse.value = evt.inverse;

    nextTick(() => {
        rutInputRef.value.inputRef?.focus();
    });
});

onUnmounted(() => {
    removeListeners();
});
</script>

<style lang="scss" scoped>
.portafolio-create {
    &__actions:deep(.icon-container) {
        cursor: pointer;
    }
}

.login--drawer {
    &:deep(.orq-button) {
        width: 100%;
    }

    .view-more--button {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        color: var(--action-secondary-enabled);
        width: fit-content;

        * {
            font-size: 10px !important;
            font-weight: 400;
        }

        &.rotate :deep(.icon-container) {
            transform: rotate(180deg);
        }
    }

    .drawer--title {
        color: var(--on-color-emphasis-high);
        font-size: 18px;
        line-height: 125%;
    }

    .drawer--subtitle {
        color: var(--on-color-emphasis-low);
        font-size: 14px;
        line-height: 150%; /* 21px */
    }

    .drawer--forgot {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            text-decoration: none;
            color: var(--action-secondary-enabled);
        }
    }

    .drawer--disclaimer-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .drawer--disclaimer {
        color: var(--on-color-emphasis-medium);
        font-size: 10px;
        line-height: 125%; /* 12.5px */

        :deep(a) {
            font-size: 10px;
            color: var(--action-secondary-enabled);
        }
    }

    & :deep(.orq-input__input:-internal-autofill-selected),
    & :deep(.orq-input__input:-internal-autofill-selected:focus),
    & :deep(.orq-input__input:-internal-autofill-selected:hover) {
        -webkit-text-fill-color: var(--emphasis-base-medium);
        box-shadow: 0 0 0 1000px var(--neutral-base) inset;
        transform: translateY(-1px);
    }

    & :deep(.orq-input--inverse__input:-internal-autofill-selected),
    & :deep(.orq-input--inverse__input:-internal-autofill-selected:focus),
    & :deep(.orq-input--inverse__input:-internal-autofill-selected:hover) {
        -webkit-text-fill-color: var(--emphasis-base-medium-inverse);
        box-shadow: 0 0 0 1000px var(--neutral-base-inverse) inset;
        transform: translateY(-1px);
    }
}

.inverse {
    color: var(--on-color-emphasis-high-inverse);

    .view-more--button {
        color: var(--action-secondary-enabled-inverse);
    }

    .drawer--forgot {
        a {
            color: var(--action-secondary-enabled-inverse);
        }
    }
    .drawer--title {
        color: var(--on-color-emphasis-high-inverse);
    }

    .drawer--subtitle {
        color: var(--on-color-emphasis-low-inverse);
    }
    .drawer--disclaimer {
        color: var(--on-color-emphasis-medium-inverse);

        :deep(a) {
            color: var(--action-secondary-enabled-inverse);
        }
    }
}
</style>
