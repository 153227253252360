import { i18n } from '@/i18n';
const { t } = i18n.global;
/**
 * We are going to keep every constant here (Unless this file grows up giantly)
 * every constant SHOULD go in uppercase so we can identify them everywhere
 * Please Add a description to every constan
 */

/**Todos los tipos de instrumentos que entrega el servicio */
export const TYPES = {
    ACCION: 10,
    FONDO_MUTUO: 6,
    RENTA_FIJA: 30,
    FONDO_DE_INVERSION: 7,
    FONDO_DE_INVERSION_MULTITYPE: 700, // "Es un fondo de inversión que no transa por bolsa, si no que tiene aportes y rescates diarios y con horario específico. La interfaz sería la misma que para aportes y rescates de Fondos Mutuos." https://btgpactual.atlassian.net/browse/DCH-820?atlOrigin=eyJpIjoiYmMwMjg2YTYyNDY1NGUyOTg1NjE4Njk0MDU3M2QyYjAiLCJwIjoiaiJ9
    FONDO_INTERNACIONAL: 20, // all funds
    FONDO_MUTUO_INTERNACIONAL: 4, // all funds
    MONEDA: 2,
    MONEDA_INV: 1040, // Esta aparece en el rescate
    TASA: 5, // No se pueden comprar.
    INDICE: 3, // No se pueden comprar.
    CAJA_CONTADO: 100, // No se pueden comprar.
    CAJA_COMPROMISOS_FUTUROS: 101, // No se pueden comprar.
    FONDOS_EN_TRANSITO: 102, // No se pueden comprar.
    NOTAS_ESTRUCTURADAS: 60, // No se pueden comprar.
    DEPOSITO_A_PLAZO: 37,
    BILLETERA_ML: 1000,
    COMMODITY: 1,
    OPCIONES: 36,
    FONDOS_INMOBILIARIOS: 35,
    HEDGE_FUNDS: 34,
    CERTIFICADO: 38,
    OTRO: 9,
    FORWARD: 39,
    ABONO: 40,
    GIRO: 41,
    DEFAULT: 0, // No se pueden comprar.
};

export const TYPES_DATA = {
    [TYPES.ACCION]: {
        name: t('consts.typesData.accion.name'),
        nameType: t('consts.typesData.accion.nameType'),
        color: '#6BAA23',
        measure: t('consts.typesData.accion.measure'),
        measures: t('consts.typesData.accion.measures'),
        SELL: t('consts.typesData.sell'),
        BUY: t('consts.typesData.buy'),
        operation: {
            SELL: t('consts.typesData.operations.sell'),
            BUY: t('consts.typesData.operations.buy'),
        },
        variacion: t('consts.typesData.accion.variacion'),
        variacionMode: '1D',
        title: 'consts.typesData.accion.title',
        description: 'consts.typesData.accion.description',
    },
    [TYPES.FONDO_MUTUO]: {
        name: t('consts.typesData.fondoMutuo.name'),
        nameType: t('consts.typesData.fondoMutuo.nameType'),
        color: '#C0549C',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
        SELL: t('consts.typesData.retrieve'),
        BUY: t('consts.typesData.contribute'),
        operation: {
            SELL: t('consts.typesData.operations.retrieve'),
            BUY: t('consts.typesData.operations.contribute'),
        },
        variacion: t('consts.typesData.fondoMutuo.variacion'),
        variacionMode: '1M',
        title: 'consts.typesData.fondoMutuo.title',
        description: 'consts.typesData.fondoMutuo.description',
    },
    [TYPES.FONDO_DE_INVERSION_MULTITYPE]: {
        name: t('consts.typesData.fondoMultitype.name'),
        nameType: t('consts.typesData.fondoMultitype.nameType'),
        color: '#329FB5',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
        SELL: t('consts.typesData.retrieve'),
        BUY: t('consts.typesData.contribute'),
        operation: {
            SELL: t('consts.typesData.operations.retrieve'),
            BUY: t('consts.typesData.operations.contribute'),
        },
        variacion: t('consts.typesData.fondoMultitype.variacion'),
        variacionMode: '1M',
        title: 'consts.typesData.fondoMultitype.title',
        description: 'consts.typesData.fondoMultitype.description',
    },
    [TYPES.FONDO_DE_INVERSION]: {
        name: t('consts.typesData.fondoInversion.name'),
        nameType: t('consts.typesData.fondoInversion.nameType'),
        color: '#329FB5',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
        SELL: t('consts.typesData.sell'),
        BUY: t('consts.typesData.buy'),
        operation: {
            SELL: t('consts.typesData.operations.sell'),
            BUY: t('consts.typesData.operations.buy'),
        },
        variacion: t('consts.typesData.fondoInversion.variacion'),
        variacionMode: '1M',
        title: 'consts.typesData.fondoInversion.title',
        description: 'consts.typesData.fondoInversion.description',
    },
    [TYPES.NOTAS_ESTRUCTURADAS]: {
        name: t('consts.typesData.notas.name'),
        nameType: t('consts.typesData.notas.nameType'),
        color: '#9E664D',
        measure: t('consts.typesData.notas.measure'),
        measures: t('consts.typesData.notas.measures'),
        SELL: t('consts.typesData.sell'),
        BUY: t('consts.typesData.buy'),
        operation: {
            SELL: t('consts.typesData.operations.sell'),
            BUY: t('consts.typesData.operations.buy'),
        },
        variacion: t('consts.typesData.notas.variacion'),
        variacionMode: '1M',
        title: 'consts.typesData.notas.title',
        description: 'consts.typesData.notas.description',
    },
    [TYPES.FONDO_INTERNACIONAL]: {
        name: t('consts.typesData.fondoInternacional.name'),
        nameType: t('consts.typesData.fondoInternacional.nameType'),
        color: '#4854C7',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
        SELL: t('consts.typesData.retrieve'),
        BUY: t('consts.typesData.contribute'),
        operation: {
            SELL: t('consts.typesData.operations.retrieve'),
            BUY: t('consts.typesData.operations.contribute'),
        },
        variacion: t('consts.typesData.fondoInternacional.variacion'),
        variacionMode: '1M',
        title: 'consts.typesData.fondoInternacional.title',
        description: 'consts.typesData.fondoInternacional.description',
    },
    [TYPES.FONDO_MUTUO_INTERNACIONAL]: {
        name: t('consts.typesData.fondoInternacional.name'),
        nameType: t('consts.typesData.fondoInternacional.nameType'),
        color: '#4854C7',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
        SELL: t('consts.typesData.retrieve'),
        BUY: t('consts.typesData.contribute'),
        operation: {
            SELL: t('consts.typesData.operations.retrieve'),
            BUY: t('consts.typesData.operations.contribute'),
        },
        variacion: t('consts.typesData.fondoInternacional.variacion'),
        variacionMode: '1M',
        title: 'consts.typesData.fondoInternacional.title',
        description: 'consts.typesData.fondoInternacional.description',
    },
    [TYPES.MONEDA]: {
        name: t('consts.typesData.moneda.name'),
        nameType: t('consts.typesData.moneda.nameType'),
        color: '#909090',
        measure: t('consts.typesData.moneda.measure'),
        measures: t('consts.typesData.moneda.measures'),
        SELL: t('consts.typesData.sell'),
        BUY: t('consts.typesData.buy'),
        operation: {
            SELL: t('consts.typesData.operations.sell'),
            BUY: t('consts.typesData.operations.buy'),
        },
        variacion: t('consts.typesData.moneda.variacion'),
        variacionMode: '1D',
        title: 'consts.typesData.moneda.title',
        description: 'consts.typesData.moneda.description',
    },
    [TYPES.MONEDA_INV]: {
        name: t('consts.typesData.monedaInversion.name'),
        nameType: t('consts.typesData.monedaInversion.nameType'),
        color: '#6BAAFF',
        measure: t('consts.typesData.moneda.measure'),
        measures: t('consts.typesData.moneda.measures'),
        SELL: t('consts.typesData.sell'),
        BUY: t('consts.typesData.buy'),
        operation: {
            SELL: t('consts.typesData.operations.sell'),
            BUY: t('consts.typesData.operations.buy'),
        },
        variacion: t('consts.typesData.monedaInversion.variacion'),
        variacionMode: '1D',
    },
    [TYPES.TASA]: {
        name: t('consts.typesData.tasa.name'),
        nameType: t('consts.typesData.tasa.nameType'),
        color: '#fe7f2d',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
        variacion: t('consts.typesData.tasa.variacion'),
        variacionMode: '1M',
    },
    [TYPES.INDICE]: {
        name: t('consts.typesData.indice.name'),
        nameType: t('consts.typesData.indice.nameType'),
        color: '#cccccc',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
        title: 'consts.typesData.indice.title',
        description: 'consts.typesData.indice.description',
        variacion: t('consts.typesData.indice.variacion'),
        variacionMode: '1D',
    },
    [TYPES.CAJA_CONTADO]: {
        name: t('consts.typesData.cajaContado.name'),
        color: '#149A5A',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
    },
    [TYPES.CAJA_COMPROMISOS_FUTUROS]: {
        name: t('consts.typesData.cajaCompromisos.name'),
        nameType: t('consts.typesData.cajaCompromisos.nameType'),
        color: '#c499fe',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
    },
    [TYPES.FONDOS_EN_TRANSITO]: {
        name: t('consts.typesData.fondosTransito.name'),
        nameType: t('consts.typesData.fondosTransito.nameType'),
        color: '#FF5651',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
    },
    [TYPES.DEPOSITO_A_PLAZO]: {
        name: t('consts.typesData.depositoPlazo.name'),
        nameType: t('consts.typesData.depositoPlazo.nameType'),
        color: '#307AE0',
        measure: t('consts.typesData.depositoPlazo.measure'),
        measures: t('consts.typesData.depositoPlazo.measures'),
        SELL: t('consts.typesData.sell'),
        BUY: t('consts.typesData.buy'),
        operation: {
            SELL: t('consts.typesData.operations.sell'),
            BUY: t('consts.typesData.operations.buy'),
        },
        title: 'consts.typesData.depositoPlazo.title',
        description: 'consts.typesData.depositoPlazo.description',
    },
    [TYPES.RENTA_FIJA]: {
        name: t('consts.typesData.rentaFija.name'),
        nameType: t('consts.typesData.rentaFija.nameType'),
        color: '#E8B73D',
        measure: '',
        measures: '',
        BUY: '',
    },
    [TYPES.OPCIONES]: {
        name: t('consts.typesData.opciones.name'),
        nameType: t('consts.typesData.opciones.nameType'),
        color: '#BCDC98',
        measure: '',
        measures: '',
        BUY: '',
    },
    [TYPES.BILLETERA_ML]: {
        name: t('consts.typesData.billetera.name'),
        nameType: t('consts.typesData.billetera.nameType'),
        color: '#149A5A',
        measure: '-',
        measures: '-',
        BUY: '-',
    },
    [TYPES.COMMODITY]: {
        name: t('consts.typesData.commodity.name'),
        nameType: t('consts.typesData.commodity.nameType'),
        color: '#F2F2F2',
        measure: '-',
        measures: '-',
        BUY: '-',
        variacion: t('consts.typesData.commodity.variacion'),
        variacionMode: '1M',
    },
    [TYPES.CERTIFICADO]: {
        name: t('consts.typesData.certificado.name'),
        nameType: t('consts.typesData.certificado.nameType'),
        color: '#909090',
        measure: '-',
        measures: '-',
        BUY: '-',
        variacion: t('consts.typesData.certificado.variacion'),
        variacionMode: '1M',
    },
    [TYPES.HEDGE_FUNDS]: {
        name: t('consts.typesData.hedgeFunds.name'),
        nameType: t('consts.typesData.hedgeFunds.nameType'),
        color: '#D289BA',
        measure: '-',
        measures: '-',
        BUY: '-',
        variacion: t('consts.typesData.hedgeFunds.variacion'),
        variacionMode: '1M',
    },
    [TYPES.OTRO]: {
        name: t('consts.typesData.otro.name'),
        nameType: t('consts.typesData.otro.nameType'),
        color: '#EF7640',
        measure: '-',
        measures: '-',
        BUY: '-',
        variacion: t('consts.typesData.otro.variacion'),
        variacionMode: '1M',
    },
    [TYPES.FONDOS_INMOBILIARIOS]: {
        name: t('consts.typesData.fondoInmobiliario.name'),
        nameType: t('consts.typesData.fondoInmobiliario.nameType'),
        color: '#754CC5',
        measure: '-',
        measures: '-',
        BUY: '-',
        variacion: t('consts.typesData.fondoInmobiliario.variacion'),
        variacionMode: '1M',
    },
    [TYPES.FORWARD]: {
        name: t('consts.typesData.forward.name'),
        nameType: t('consts.typesData.forward.nameType'),
        color: '#5ABED3',
        measure: '-',
        measures: '-',
        BUY: '-',
        variacionMode: '1M',
    },
    [TYPES.GIRO]: {
        name: t('consts.typesData.giro.name'),
        nameType: t('consts.typesData.giro.nameType'),
        operation: {
            SELL: t('consts.typesData.operations.withdrawal'),
            BUY: t('consts.typesData.operations.pay'),
        },
    },
    [TYPES.ABONO]: {
        name: t('consts.typesData.abono.name'),
        nameType: t('consts.typesData.abono.nameType'),
        operation: {
            SELL: t('consts.typesData.operations.withdrawal'),
            BUY: t('consts.typesData.operations.pay'),
        },
    },
    [TYPES.DEFAULT]: {
        name: t('consts.typesData.default.name'),
        nameType: t('consts.typesData.default.nameType'),
        color: '#cccccc',
        measure: t('consts.typesData.measure'),
        measures: t('consts.typesData.measures'),
        SELL: t('consts.typesData.withdrawal'),
        BUY: t('consts.typesData.pay'),
        operation: {
            SELL: t('consts.typesData.operations.withdrawal'),
            BUY: t('consts.typesData.operations.pay'),
        },
    },
};

/**Tipos de Token */
export const TOKEN_TYPE = {
    SMS: 1,
    SOFTTOKEN: 2,
};

/** Respuestas del servicio de compras o ventas de Acciones o Fondos */
export const RESPONSES_STATUS = {
    SUCCESS: 'exito',
    TOKEN_ERROR: '400 Bad Request: [Token incorrecto]',
    ERROR: 'Error',
    SERVER_ERROR: 'Internal Server Error',
};

/** Tipo de Operacion realizada en Fondos */
export const OPERATION = {
    BUY: 1,
    SELL: 2,
};

/** Tipo de arquitectura de inversión */
export const MARKET = {
    BOLSA: 1,
    NAV: 2,
};

// Network status
export const STATUS = {
    IDLE: 'idle',
    LOADING: 'loading',
    DONE: 'done',
};

/**
 * This is a huge number used when the max amount to BUY/invest is 0
 * Why? To keep things simple
 */
export const MAX_AMOUNT = 99999999999999;

/**
 *
 * This is the maximum spread limit (Percentage difference at the current price)
 * in shares and ffii per stock
 */

export const MAX_SPREAD = 5;

/**
 *
 * This is the maximum spread limit (Percentage difference at the current price)
 * in shares and ffii per stock
 */

export const MAX_CUOTA_PERCENT = 90;

export const CURRENCY = {
    CLP: '$',
    USD: 'US$',
    UF: 'UF',
    PEN: 'PEN',
};

/**
 * Obol status
 */
export const OBOL_CODE = {
    SINACOFI: '1',
    PERSONA_JURIDICA: '2',
    EXTRANJERO: '3',
    MENOR_DE_EDAD: '4',
    VALIDACION_AML: '5',
    VALIDACION_RIESGO: '6',
    VALIDACION_COMISIONES: '7',
    INVITACION_EN_CURSO: '8',
    PROCESO_DE_DISTRIBUCION: '9',
    TOO_MANY_TRIES: '10',
    PRINGADO: 'NOK',
};

export const SERVER_STATUS = {
    OK: 0,
    NOTRANS: 1,
    DOWN: 2,
};

export const MAIL_ADDRESS = 'ml@btgpactual.com';

export const LINKS = {
    VISOR_ML: 'https://btgpactual.cl/inversiones-digitales/blog/',
};

export const UICONFIG = {
    INPUT_DELAY: 500,
};

export const FORMAT_PRESETS = {
    NONE: {},
    PLAIN: {
        preffix: '',
        minDecimalDigits: 0,
        maxDecimalDigits: 0,
    },
    PLAINEXT: {
        preffix: '',
        minDecimalDigits: 2,
        maxDecimalDigits: 2,
    },
    CLP: {
        preffix: CURRENCY.CLP,
        minDecimalDigits: 0,
        maxDecimalDigits: 0,
        prefixSpace: false,
    },
    CLPEXT: {
        preffix: CURRENCY.CLP,
        minDecimalDigits: 2,
        maxDecimalDigits: 2,
        prefixSpace: false,
    },
    UF: {
        preffix: CURRENCY.UF,
        minDecimalDigits: 4,
        maxDecimalDigits: 4,
        prefixSpace: true,
    },
    USD: {
        preffix: CURRENCY.USD,
        minDecimalDigits: 2,
        maxDecimalDigits: 2,
        prefixSpace: false,
    },
    USDEXT: {
        preffix: CURRENCY.USD,
        minDecimalDigits: 0,
        maxDecimalDigits: 0,
        prefixSpace: false,
    },
    PERCENT: {
        suffix: '%',
        minDecimalDigits: 4,
        maxDecimalDigits: 4,
    },
    PERCENTSIMPLE: {
        suffix: '%',
        minDecimalDigits: 0,
        maxDecimalDigits: 0,
    },
    PERCENTDOUBLE: {
        minDecimalDigits: 2,
        maxDecimalDigits: 2,
    },
    COUNT: {
        preffix: '#',
        minDecimalDigits: 0,
        maxDecimalDigits: 0,
        prefixSpace: false,
    },
    POINTS: {
        preffix: '',
        minDecimalDigits: 4,
        maxDecimalDigits: 4,
        prefixSpace: false,
    },
    'Nuevo Sol': {
        preffix: CURRENCY.PEN,
        minDecimalDigits: 2,
        maxDecimalDigits: 2,
        prefixSpace: true,
    },
    PEN: {
        preffix: CURRENCY.PEN,
        minDecimalDigits: 2,
        maxDecimalDigits: 2,
        prefixSpace: true,
    },
};

export const BANKS = {
    Scotiabank: {
        id: 7,
        max: 99000000,
    },
    Santander: {
        id: 5,
        max: 900000000,
    },
    Chile: {
        id: 6,
        max: 450000000,
    },
    BCI: {
        id: 3,
        max: 400000000,
    },
    BICE: {
        id: 4,
        max: 100000000,
    },
    Itau: {
        id: 8,
        max: 40000000,
    },
};

export const TIPO_PERSONA = {
    NATURAL: '1',
    EMPRESA: '2',
};

export const STATES = {
    Ingresado: {
        label: t('consts.states.ingresado'),
        type: 'info-on',
    },
    'No ingresado': {
        label: t('consts.states.noIngresado'),
        type: 'negative',
    },
    'No Ingresado': {
        label: t('consts.states.noIngresado'),
        type: 'negative',
    },
    Ejecutado: {
        label: t('consts.states.ejecutado'),
        type: 'positive',
    },
    Cancelado: {
        label: t('consts.states.cancelado'),
        type: 'warning',
    },
    Cancelable: {
        label: t('consts.states.ingresado'),
        type: 'info-on',
    },
    Vencido: {
        label: t('consts.states.vencido'),
        type: 'positive',
    },
};

export const CURRENCY_FORMAT = {
    PESOCHILENO: 'CLP',
    PESOS_CHILENOS: 'CLP',
    CLP: 'CLP',
    UF: 'UF',
    US_DOLLAR: 'USD',
    USD: 'USD',
    JPY: 'JPY',
    PEN: 'PEN',
    'Nuevo Sol': 'PEN',
    GBP: 'GBP',
    default: 'CLP',
};

export const ARCHITECTURE = {
    NAV: 'nav',
    BOLSA: 'bolsa',
    NAV_Y_BOLSA: 'navybolsa',
};

// export const WINDOWS_OPERATION = {
//     BUY: 1, // Aporte
//     SELL: 2, // Rescate
// };

export const EVENTS = {
    TRANSACCIONAL_OPEN: 'TRANSACCIONAL:OPEN',
    TRANSACCIONAL_CLOSE: 'TRANSACCIONAL:CLOSE',
    TRANSACCIONAL_FINISHED: 'TRANSACCIONAL:FINISHED',
    INTERCOM_OPEN: 'INTERCOM:OPEN',
    INTERCOM_OPEN_SPACE: 'INTERCOM:OPEN:SPACE',
    MONITOR: 'MONITOR',
    FAVORITE_OPEN: 'FAVORITE:OPEN',
    ALERT_OPEN: 'ALERT:OPEN',
    SIMULATION_OPEN: 'SIMULATION:OPEN',
    CONFIRM: 'CONFIRM:OPEN',
    QUICK_LOGIN_OPEN: 'QUICKLOGIN:OPEN',
    TOAST: 'TOAST:OPEN',
    TOAST_CLOSE: 'TOAST:CLOSE',
    ABONAR_OPEN: 'ABONAR:OPEN',
    ABONAR_CLOSE: 'ABONAR:CLOSE',
    RETIRAR_OPEN: 'RETIRAR:OPEN',
    RETIRAR_CLOSE: 'RETIRAR:CLOSE',
    PAYMENT: 'PAYMENT',
    OBOL_ERROR: 'OBOL:ERROR',
};

export const INTERCOM_VAR = {
    QUESTION: 4,
};

export const PRICE_TYPES = {
    PEAK: 0,
    LIMIT: 1,
};

export const INVEST_TYPES = {
    AMOUNT: 0,
    QUANTITY: 1,
};

export const PRICES = {
    LIMIT_PERCENT: 5,
};

export const SELL_MODES = {
    PERCENT: 1,
    AMOUNT: 2,
};

export const SECTORS = {
    // TODO: Maybe move this logic to other component
    // TODO: add logos
    1: {
        icon: 'transportation',
    },
    7: {
        icon: 'telecomunication',
    },
    8: {
        icon: 'retail',
    },
    10: {
        icon: 'supermarket',
    },
    11: {
        icon: 'energy',
    },
    12: {
        icon: 'ex-bank',
    },
    13: {
        icon: 'medicine',
    },
    14: {
        icon: 'transportation',
    },
    15: {
        icon: 'it',
    },
    16: {
        icon: 'metals',
    },
    17: {
        icon: 'property',
    },
    18: {
        icon: 'sanitation',
    },
};

export const LABEL_STYLE = {
    DEFAULT: '',
    SHORT: 'short',
};

export const WS_UPDATE = {
    none: 'none',
    single: 'single',
    block: 'block',
};

export const DAP_TYPES = {
    CLP: {
        WEEKLY: {
            name: t('consts.dap.clpSevenDays'),
            idCode: 'DAP.DAPBTGDAPCLP.DAP',
            term: 7,
        },
        MONTHLY: {
            name: t('consts.dap.clpOneMonth'),
            idCode: 'DAP.DAPBTGDAPCLP.DAP',
            term: 30,
        },
    },
    UF: {
        YEARLY: {
            name: t('consts.dap.ufOneYear'),
            idCode: 'DAP.DAPBTGDAPUF.DAP',
            term: 365,
        },
    },
};

/**
 * RISK: Used to define what kind of risk we have
 * The service is returning a string, so we are doing the conversion to num
 * these Nums are required to display the risk and simulate (Among other uses)
 */
export const RISK = {
    uc: {
        value: 1,
        label: t('consts.risk.uc.label'),
        icon: 'conservative',
        item: null,
        key: 'uc',
        detailedKey: 'ultraconservador',
        color: '--primary-extended-70',
        meaning: t('consts.risk.uc.meaning'),
        image: '/images/autoinvest-conservador.png',
    },
    c: {
        value: 2,
        label: t('consts.risk.c.label'),
        icon: 'conservative',
        availableGoals: true,
        description: t('consts.risk.c.desc'),
        item: {
            idEncoded:
                'Rk5ELkZNQlRHUEFDVFVBTEdFU1RJT05DT05TRVJWQURPUkFTRVJJRURJR0lUQUwuRk0=',
            type: TYPES.FONDO_MUTUO,
            title: t('autoinvest.conservador'),
            name: 'BTG Pactual Gestión Conservadora',
            desc: t('autoinvest.conservadorDesc'),
            link: '/autoinvest/conservador',
            class: 'bg--primary-extended-70',
            image: '/images/autoinvest-conservador.png',
        },
        key: 'c',
        detailedKey: 'conservador',
        color: '--primary-extended-70',
        meaning: t('consts.risk.c.meaning'),
        image: '/images/autoinvest-conservador.png',
    },
    b: {
        value: 3,
        label: t('consts.risk.b.label'),
        icon: 'moderate',
        availableGoals: true,
        description: t('consts.risk.b.desc'),
        item: {
            idEncoded:
                'Rk5ELkZNQlRHUEFDVFVBTEdFU1RJT05BQ1RJVkFTRVJJRURJR0lUQUwuRk0=',
            type: TYPES.FONDO_MUTUO,
            name: 'BTG Pactual Gestión Activa',
            title: t('autoinvest.moderado'),
            desc: t('autoinvest.moderadoDesc'),
            link: '/autoinvest/moderado',
            class: 'bg--primary-extended-90',
            image: '/images/autoinvest-moderado.png',
        },
        key: 'b',
        detailedKey: 'moderado',
        color: '--primary-extended-90',
        meaning: t('consts.risk.b.meaning'),
        image: '/images/autoinvest-moderado.png',
    },
    a: {
        value: 4,
        label: t('consts.risk.a.label'),
        nicename: t('consts.risk.a.nicename'),
        availableGoals: true,
        icon: 'aggressive',
        description: t('consts.risk.a.desc'),
        item: {
            idEncoded:
                'Rk5ELkZNQlRHUEFDVFVBTEdFU1RJT05BR1JFU0lWQVNFUklFRElHSVRBTC5GTQ==',
            type: TYPES.FONDO_MUTUO,
            title: t('autoinvest.agresivo'),
            name: 'BTG Pactual Gestión Agresiva',
            desc: t('autoinvest.agresivoDesc'),
            link: '/autoinvest/agresivo',
            class: 'bg--primary-extended-100',
            image: '/images/autoinvest-agresivo.png',
        },
        key: 'a',
        detailedKey: 'agresivo',
        color: '--primary-extended-100',
        meaning: t('consts.risk.a.meaning'),
        image: '/images/autoinvest-agresivo.png',
    },
    ua: {
        value: 5,
        label: t('consts.risk.ua.label'),
        icon: 'aggressive',
        item: null,
        key: 'ua',
        detailedKey: 'ultraagresivo',
        redirect: 'agresivo',
        color: '--primary-extended-100',
        meaning: t('consts.risk.ua.meaning'),
        image: '/images/autoinvest-agresivo.png',
    },
};

export const PASS_STATUS = {
    CHANGE: 0,
    NORMAL: 1,
};

export const RANDOM_PALETTE = [
    'var(--support-blue-50)',
    'var(--support-blue-60)',
    'var(--support-yellow-60)',
    'var(--support-pink-60)',
    'var(--support-aqua-60)',
    'var(--support-purple-60)',
    'var(--support-lime-60)',
    'var(--support-brown-60)',
    'var(--support-green-60)',
    'var(--support-orange-50)',
    'var(--support-grey-60)',
    'var(--support-pink-50)',
    'var(--support-violet-60)',
    'var(--support-orange-60)',
    'var(--support-red-60)',
    '#C768A7',
    '#7EB63E',
    '#8562CB',
    '#5B66CD',
    '#F42D55',
    '#FF5A10',
    '#E45285',
    '#3E7ACC',
    '#94BDBD',
    '#5A4A73',
    '#263526',
    '#FFEE83',
    '#3B75C5',
    '#C5395A',
    '#19295A',
    '#B4FFBD',
    '#66D9FF',
    '#7362AC',
    '#FFDE00',
    '#3B6066',
    '#41D552',
    '#A42250',
    '#265595',
];

export const PERSONAL_DATA = {
    PERSONAL_INFO: 0,
    CONTACT_INFO: 1,
    ADDRESSES: 2,
};

export const LOGIN_ERROR_STATUS = {
    GENERIC_ERROR: 'err.02',
    'err.02': 'RUT y/o contraseña incorrecta',
    'err.18': 'RUT y/o contraseña incorrecta / Reinicio contraseña',
    'err.06': 'Usuario bloqueado',
};

export const FORMS_DEFAULTS = {
    NO_COMPLEMENT: '-999',
};

export const CODIGO_SADCO = {
    ANEXO_A: 58,
};

export const ACCOUNT_TYPE = {
    INVESTMENT: '4',
    BANK: '12',
};

export const MOVEMENT_BANK_DEFAULTS = {
    OUT_OF_MOVES: 'SIN MOVIMIENTOS',
};

export const TAGS = {
    ETF: 8,
};

export const BANK_ACCOUNTS_TYPES = {
    CUENTA_CORRIENTE: 1,
    CUENTA_VISTA: 3,
};

export const DOCUMENTS = {
    FICHA_PN: "72",
    FICHA_PJ: "74",
    NCG380: "54",
    ANEXO_A: "58",
};
