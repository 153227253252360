<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :title="title"
                :subtitle="subtitle"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyAmount
                    :title="t('transactions.availableAmount')"
                    :amount="availableAmount"
                    :currency="currency"
                    :inverse="inverse"
                    hide-link
                />
                <BodySellAmount
                    v-model:amount.number="order.amount"
                    v-model:mode="order.mode"
                    v-focus
                    :label="t('transactions.amountToRescue')"
                    :buttons="percentButtons"
                    :available-balance="availableAmount"
                    :currency="currency"
                    :error-msg="errorMsg"
                    :inverse="inverse"
                    @update:amount="debouncedTouch"
                />

                <OrqNotificationFixed
                    v-if="v$.$errors[0]?.$validator === 'lessThanNinetyPercent'"
                    notification-type="fixed"
                    :label="t('transactions.moreThanPercentNotification')"
                    kind="info"
                    :label-size="12"
                />

                <BodyInfo :data="investInfo" :inverse="inverse" />
            </OrqColumn>
        </template>

        <template #footer>
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid"
                :inverse="inverse"
                :loading="amountRescueAnticipatedLoading"
                @next="nextStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed } from 'vue';
import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from '../TransactionalFooter.vue';
import BodySellAmount from '../Shared/BodySellAmount.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import BodyAmount from '../body-components/BodyAmount.vue';
import {
    OrqDrawerBody,
    OrqColumn,
    OrqNotificationFixed,
} from '@digital/orquestra';
import { TYPES_DATA, OPERATION, SELL_MODES, MAX_CUOTA_PERCENT } from '@/consts';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { debounce } from 'lodash';

import {
    overZero,
    nonZero,
    minValue,
    maxValue,
    moreThanPercent,
} from '@/_helpers/rules.helper.js';
import { focus as vFocus } from '@/directives/focus.js';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps, amountRescueAnticipatedLoading } =
    storeToRefs(orderStore);
const { initOrder, nextStep } = orderStore;

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { accounts, currentAccount } = storeToRefs(accountsStore);

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { paymentMethods, balance: insBalance } = storeToRefs(instrumentStore);

// From here, the refs, this should be connected to specific components.
/* eslint-disable no-unused-vars */
const portfolioFFMM = computed(() => {
    return currentAccount.value.prtfolioFFM
        ? currentAccount.value.prtfolioFFM
        : accounts.value[0].prtfolioFFMM;
});

const bank = computed(() => {
    return ' ';
});

const detail = computed(() => {
    return paymentMethods[0]?.paramCodigo;
});
/* eslint-enable no-unused-vars */
// Till here.

const { t } = useI18n();

const title = computed(() => {
    return props.item?.info?.name;
});

const subtitle = computed(() => {
    return TYPES_DATA[props.item?.info?.type?.id]?.nameType ?? '';
});

const debouncedTouch = debounce(() => {
    if (props.item?.config?.retiroAnticipado) {
        orderStore.getAmountRescueAnticipated();
    }
    v$.value.$touch();
}, 500);

const isValid = computed(
    () => v$.value.$dirty && v$.value.$errors.length === 0
);

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    availableAmount: {
        type: Number,
        default: 0,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const percentButtons = computed(() => [
    {
        label: t('transactions.percentRescue', { percent: '100%' }),
        percent: 100,
    },
]);

const investInfo = computed(() => {
    if (props.item?.config?.multiproduct) {
        return [
            {
                title: t('transactions.liquidityPeriod'),
                value: {
                    label: t('transactions.day', props.item?.config?.rescTime),
                    format: currency.value,
                },
            },
        ];
    }

    return [
        ...(props.item?.config?.sellMaxAmount > 0
            ? [
                  {
                      title: t('transactions.maxSellAmount'),
                      value: {
                          amount: props.item?.config?.sellMaxAmount,
                          format: currency.value,
                      },
                  },
              ]
            : []),
        {
            title: t('transactions.liquidityPeriod'),
            value: {
                label: t('transactions.day', props.item?.config?.rescTime),
                format: currency.value,
            },
        },
        ...(props.item?.config?.retiroAnticipado
            ? [
                  {
                      title: t('transactions.earlyRescueCommission'),
                      value: {
                          label:
                              props.item?.config?.comisionRescate !== ''
                                  ? props.item?.config?.comisionRescate
                                  : '--',
                      },
                  },
              ]
            : []),
    ];
});

const rules = computed(() => {
    const defaultRules = {
        balanceOverZero: overZero(props.availableAmount),
        moreThanAmount: maxValue(
            props.availableAmount,
            t('transactions.moreThanAmountSell')
        ),
        nonZero: nonZero(t('transactions.lessThanMinSell')),
    };
    if (props.item?.config?.sellMinAmount > 0) {
        defaultRules.moreThanMin = minValue(props.item?.config?.sellMinAmount);
    }
    if (props.item?.config?.sellMaxAmount > 0) {
        defaultRules.moreThanMax = maxValue(props.item?.config?.sellMaxAmount);
    }
    const amountRules = {
        lessThanNinetyPercent: moreThanPercent(
            props.availableAmount,
            t('transactions.moreThanPercent', { percent: MAX_CUOTA_PERCENT })
        ),
    };

    return {
        amount:
            order.value.mode === SELL_MODES.AMOUNT
                ? { ...defaultRules, ...amountRules }
                : defaultRules,
    };
});

const v$ = useVuelidate(rules, order);

const errorMsg = computed(() => v$.value.$errors[0]?.$message ?? null);

const currency = computed(() => {
    return props.item?.config?.multiproduct || props.item?.config?.multiCurrency
        ? 'CLP'
        : props.item?.config?.currency;
});

initOrder({
    idEncoded: props.item?.idEncoded,
    price: props.item?.insPrice?.price,
    quantity: insBalance.value.quantity,
    prtfolioFFM: portfolioFFMM.value,
    amount: 0,
    bank: bank.value,
    detail: detail.value,
    mode: SELL_MODES.AMOUNT,
    operation: OPERATION.SELL,
    currency,
});
</script>


