<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :title="title"
                :subtitle="subtitle"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyAmount
                    :amount="balance[item?.config?.currency]"
                    :currency="item?.config?.currency"
                    :inverse="inverse"
                />
                <BodyInvestAmount
                    v-model.number="inputAmount"
                    :available-balance="balance[item?.config?.currency]"
                    :currency="item?.config?.currency"
                    :error-msg="errorMsg"
                    :inverse="inverse"
                    @update:model-value="debouncedGetCommission()"
                />
                <BodyInfo :data="investInfo" :inverse="inverse" />
                <div
                    v-if="props.item?.config?.mirrorIns"
                    class="switch-container"
                >
                    <div class="switch-label">
                        <span
                            class="body-baseline"
                            :class="`text--emphasis-base-high${
                                inverse ? '-inverse' : ''
                            }`"
                            >{{ t('transactions.secondaryMarket') }}</span
                        >

                        <OrqTooltip
                            :inverse="inverse"
                            :label="t('transactions.secondaryMarketTooltip')"
                        >
                            <OrqIconContainer
                                name="info"
                                :size="13"
                                :color="`emphasis-base-high${
                                    inverse ? '-inverse' : ''
                                }`"
                            />
                        </OrqTooltip>
                    </div>
                    <OrqSwitcher
                        v-if="isMirrorOpen"
                        :checked="switcher"
                        :inverse="inverse"
                        @change="switchMirror"
                    />
                    <div v-else :class="{'text--emphasis-base-high-inverse': inverse}">Cerrado</div>
                </div>
            </OrqColumn>
        </template>

        <template #footer>
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid && !commissionLoading"
                :loading="commissionLoading"
                :inverse="inverse"
                @next="nextStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { ref, computed } from 'vue';
import { debounce } from 'lodash';
import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from '../TransactionalFooter.vue';
import BodyInvestAmount from '../body-components/BodyInvestAmount.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import BodyAmount from '../body-components/BodyAmount.vue';
import { OrqDrawerBody, OrqColumn, OrqTooltip } from '@digital/orquestra';
import { OrqIconContainer, OrqSwitcher } from '@orquestra-web/vue';
import { TYPES_DATA, OPERATION, MARKET } from '@/consts';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import {
    overZero,
    nonZero,
    minValue,
    maxValue,
} from '@/_helpers/rules.helper.js';

import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS } from '@/consts';
const rootEvents = useRootEvents();

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps, commissionLoading } = storeToRefs(orderStore);
const { initOrder, nextStep, getCommission } = orderStore;

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMirrorOpen } = storeToRefs(instrumentStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);
const { t } = useI18n();
import { removeFormat } from '@/directives/currencyFormat';

const inputAmount = ref(null);

const price = computed(() => {
    return props.item?.insPrice?.sellPrice === 0
        ? props.item?.insPrice?.price
        : props.item?.insPrice?.sellPrice;
});

const quantity = computed(() => {
    // Preventing NaN at init
    const _amount = order.value.amount || 0;
    return Math.floor(_amount / price.value);
});

const title = computed(() => {
    return props.item?.info?.name;
});

const subtitle = computed(() => {
    return TYPES_DATA[props.item?.info?.type?.id]?.nameType ?? '';
});

const isValid = computed(() => {
    return v$.value.$dirty && v$.value.$errors.length === 0;
});

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const maxRemuneration = computed(() => {
    const remunerationStr = props.item?.config?.remuneration ?? '';
    const numberMatch = remunerationStr.match(/\d+,\d+/);
    if (numberMatch) {
        return Number(numberMatch[0].replace(/,/g, '.'));
    }
    return 0;
});

const debouncedGetCommission = debounce(() => {
    v$.value?.$touch();

    order.value.amount = removeFormat(inputAmount.value);
    /**
     * Important note:
     * Case 2 operation send 'amount', it doesn't matter commission or anything
     * the backend should take care of it.
     *
     * We are getting commission for display propouse only
     * The quantity is required but it should be calculated in the backend.
     */
    getCommission();
    order.value.quantity = Math.floor(order.value.amount / order.value.price);
}, 500);

const investInfo = computed(() => {
    const data = [];
    if (props.item?.config?.comisionEnabled) {
        data.push({
            title: t('transactions.commission'),
            tooltip: t('transactions.commissionTooltip'),
            value: {
                label: t('transactions.yes'),
            },
        });
    }
    data.push({
        title: t('transactions.minInvestAmount'),
        value: {
            amount: props.item?.config?.invMinAmount,
            format: props.item?.config?.currency,
        },
    });
    data.push({
        title: t('transactions.maxRemuneration'),
        value: {
            amount: maxRemuneration.value,
            format: 'PERCENT',
        },
    });
    return data;
});

const rules = computed(() => ({
    amount: {
        balanceOverZero: overZero(balance.value[props.item?.config?.currency]),
        lessThanBalance: maxValue(balance.value[props.item?.config?.currency]),
        moreThanMin: minValue(props.item?.config?.invMinAmount),
        moreThanQuota: minValue(
            Math.floor(props.item?.config?.invMinAmount),
            t('transactions.insufficientAmount')
        ),
        nonZero: nonZero(),
    },
}));

const v$ = useVuelidate(rules, order);

const errorMsg = computed(() => v$.value.$errors[0]?.$message ?? null);

const switcher = ref(false);
const switchMirror = () => {
    rootEvents.emit(EVENTS.TRANSACCIONAL_OPEN, {
        idEncoded: props.item.mirrorInsEncoded,
        operation: order.value.operation,
        inverse: props.inverse,
    });
};

initOrder({
    idEncoded: props.item?.idEncoded,
    amount: 0,
    price: price.value,
    commission: 0,
    iva: 0,
    quantity: quantity.value,
    market: MARKET.NAV,
    operation: OPERATION.BUY,
    currency: props.item?.config?.currency,
});
</script>

<style lang="scss" scoped>
.switch-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.switch-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
</style>
