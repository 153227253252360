<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :title="title"
                :subtitle="subtitle"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <OrqContentSwitcher
                    :options="options"
                    :inverse="inverse"
                    @selected="onSelectedCurrency"
                />
                <BodyAmount
                    :amount="balance[order.currency]"
                    :currency="order.currency"
                    :inverse="inverse"
                />
                <BodyInvestAmount
                    :key="order.currency"
                    v-model.number="inputAmount"
                    :available-balance="Math.floor(balance[order.currency])"
                    :currency="order.currency"
                    :error-msg="errorMsg"
                    :min-invest="
                        order.currency === 'CLP'
                            ? item?.config?.invMinAmount * rate.value
                            : item?.config?.invMinAmount
                    "
                    :inverse="inverse"
                    @update:model-value="debouncedTouch"
                />
                <BodyInfo :data="investInfo" :inverse="inverse" />
            </OrqColumn>
        </template>

        <template #footer>
            <TimerContainer
                v-if="order.showTimer"
                :id-encoded="props.item.idEncoded"
                :inverse="inverse"
            />
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid"
                :inverse="inverse"
                @next="nextStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed, ref } from 'vue';
import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from '../TransactionalFooter.vue';
import TimerContainer from '@/components/Timer/TimerContainer.vue';
import BodyInvestAmount from '../body-components/BodyInvestAmount.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import BodyAmount from '../body-components/BodyAmount.vue';
import { OrqDrawerBody, OrqColumn } from '@digital/orquestra';
import { OrqContentSwitcher } from '@orquestra-web/vue';
import { TYPES_DATA, EVENTS, OPERATION } from '@/consts';
import { useVuelidate } from '@vuelidate/core';
import {
    overZero,
    nonZero,
    minValue,
    maxValue,
} from '@/_helpers/rules.helper.js';
import { removeFormat } from '@/directives/currencyFormat';

import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';

import { useInternationalFundsStore } from '@/_store/internationalFunds.store.js';
const internationalFundsStore = useInternationalFundsStore();
const { USDRate } = storeToRefs(internationalFundsStore);

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps } = storeToRefs(orderStore);
const { initOrder, nextStep } = orderStore;

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { accounts, currentAccount } = storeToRefs(accountsStore);

import { useTimersStore } from '@/_store/timers.store.js';
const timersStore = useTimersStore();
const { timers } = storeToRefs(timersStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

import { useRootEvents } from '@/composables/useRootEvents';
import { debounce } from 'lodash';
const { addListener } = useRootEvents();
const rootEvents = useRootEvents();

const inputAmount = ref(null);

const options = computed(() => {
    let options = [
        {
            label: 'CLP',
            value: 'CLP',
            disabled: false,
            data: {
                id: 1,
            },
        },
        {
            label: 'USD',
            value: 'USD',
            disabled: false,
            data: {
                id: 2,
            },
        },
    ];
    options.forEach((item) => {
        item.selected = item.label === order.value.currency;
    });
    return options;
});

function onSelectedCurrency(evt, selected) {
    options.value.forEach((item) => {
        if (item.data.id === selected.data.id) {
            item.selected = true;
            order.value.amount = 0;
            order.value.currency = item.value;
        } else {
            item.selected = false;
        }
    });

    rootEvents.emit(EVENTS.TRANSACCIONAL_OPEN, {
        idEncoded: props.item.idEncoded,
        operation: OPERATION.BUY,
        currency: options.value.find((item) => item.selected).label,
        inverse: props.inverse,
    });

    v$.value.$reset();
}

const rate = computed(() => {
    return order.value.showTimer ? USDRate.value.id : null;
});

const portfolioFFMM = computed(() => {
    return currentAccount.value.prtfolioFFM
        ? currentAccount.value.prtfolioFFM
        : accounts.value[0].prtfolioFFMM;
});

const { t } = useI18n();

const title = computed(() => {
    return props.item?.info?.name;
});

const subtitle = computed(() => {
    return TYPES_DATA[props.item?.info?.type?.id]?.nameType ?? '';
});

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const maxRemuneration = computed(() => {
    const remunerationStr = props.item?.config?.remuneration ?? '';
    const numberMatch = remunerationStr.match(/\d+,\d+/);
    if (numberMatch) {
        return Number(numberMatch[0].replace(/,/g, '.'));
    }
    return 0;
});

addListener(EVENTS.TRANSACCIONAL_CLOSE, () => {
    timersStore.clearTimer('usdRateTimer');
});

const investInfo = computed(() => {
    const data = [
        {
            title: t('transactions.minInvestment'),
            value: {
                amount:
                    order.value.currency === 'CLP'
                        ? props.item?.config?.invMinAmount * USDRate.value.value
                        : props.item?.config?.invMinAmount,
                format: order.value.currency,
            },
        },
        {
            title: t('transactions.terRemuneration'),
            value: {
                amount: maxRemuneration.value,
                format: 'PERCENT',
            },
        },
    ];
    if (props.item?.config?.retiroAnticipado) {
        data.push({
            title: t('transactions.earlyRescueCommission'),
            value: {
                label: t('transactions.oneToThirtyDays'),
                amount: 2.38, // TODO: use correct value
                format: 'PERCENT',
            },
        });
    }
    return data;
});

const rules = computed(() => {
    const amountRules = {
        balanceOverZero: overZero(balance.value[order.value.currency]),
        moreThanMinInv: minValue(
            order.value.currency === 'CLP'
                ? props.item?.config?.invMinAmount * USDRate.value.value
                : props.item?.config?.invMinAmount,
            t('transactions.insufficientAmount')
        ),
        lessThanBalance: maxValue(balance.value[order.value.currency]),
        nonZero: nonZero(),
    };

    return {
        order: {
            amount: amountRules,
        },
    };
});

const v$ = useVuelidate(rules, { order });

const debouncedTouch = debounce(() => {
    v$.value.$touch();
    order.value.amount = removeFormat(inputAmount.value);
}, 500);

const isValid = computed(() => {
    return (
        v$.value.order.$dirty &&
        v$.value.$errors.length === 0 &&
        (!timers.value.usdRateTimer.timeUp || !order.value.showTimer)
    );
});

const errorMsg = computed(
    () => v$.value.order.amount?.$errors[0]?.$message ?? null
);

initOrder({
    idEncoded: props.item?.idEncoded,
    prtfolioFFM: portfolioFFMM.value,
    currency: order.value.currency === undefined ? 'CLP' : order.value.currency,
    rate: USDRate.value,
    showTimer: order.value.currency !== 'USD',
    operation: OPERATION.BUY,
});
</script>
